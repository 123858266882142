import { mapState, mapActions, mapGetters } from 'vuex'
import { Auth, trackEvent } from '@/plugins/firebase'
import Metrics from '@/plugins/metrics'
import { CODE, REPORT_FLOW_EXPERIMENT } from '@/globals'
import { formSteps } from '../enums/formSteps'

import SButton from '@/components/ui/s-button'
import SContainer from '@/components/ui/s-container'
import STip from '@/components/ui/s-tip'
import SLink from '@/components/ui/s-link'

import backIcon from '@/assets/images/icone_voltar_simples.svg'
import { createFormReportState } from '@/store/state-factories'

export default {
  name: 'ReportForm',
  props: {
    anonymous: {
      type: Boolean,
      default: true,
    },
    channel: {
      type: String,
    },
    step: {
      type: Number,
      default: 0,
    },
    showRelationOptions: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    StepOne: () => import('./steps/StepOne'),
    StepTwo: () => import('./steps/StepTwo'),
    StepThree: () => import('./steps/StepThree'),
    StepFour: () => import('./steps/StepFour'),
    StepFinalColab: () => import('./steps/StepFinal'),
    StepFinalOthers: () => import('./steps/StepFinalOthers'),
    StepLogin: () => import('./steps/StepLogin'),
    StepPreview: () => import('./steps/StepPreview'),
    SButton,
    SContainer,
    STip,
    SLink,
    FaqContentPage: () => import('../../Content'),
  },

  data() {
    const { anonymous, channel } = this
    const { source, reportId, step } = this.$router.currentRoute.params

    return {
      reportId,
      backIcon,
      formSteps,
      report: createFormReportState({
        anonymous,
        channel,
        source,
      }),
      currentStep: step || this.step || 0,
      showFAQDialog: false,
      showError: false,
      isSubmitted: false,
      finalStep: 4,
      isArchive: false,
      experimentName: REPORT_FLOW_EXPERIMENT,
      experimentVariant: '',
    }
  },

  async mounted() {
    window.onbeforeunload = this.beforeCloseWindow

    this.experimentVariant = await this.getExperimentVariant(
      this.experimentName,
    )

    Metrics.track('$experiment_started', {
      'Experiment name': this.experimentName,
      'Variant name': this.experimentVariant,
    })

    Metrics.register({ 'Variant name': this.experimentVariant })

    if (Auth.currentUser?.email && !this.hasMultipleCollaboratorsTypes) {
      this.isSourceCollaborator(this.report.source)
    }

    this.report.code = await this.getNextCode()

    this.resetSMSModalFlow()
    trackEvent('report_step', { step: 'one' })
  },

  computed: {
    ...mapState(['currentUser', 'error', 'isAnonymousUser', 'success']),
    ...mapGetters([
      'hasMultipleCollaboratorsTypes',
      'isFeatureEnabled',
      'isSourceCollaborator',
    ]),

    missingRequiredFields() {
      const {
        happenedTo,
        misconducts = [],
        description,
        areas = [],
        accusedSource,
        happenedAt,
        customArea,
        companyBranch,
      } = this.report

      const isHappenedToEmpty = !happenedTo || happenedTo === ''
      const isAccusedSourceEmpty = !accusedSource || accusedSource === ''

      const isHappenedAtEmpty = happenedAt === ''
      const isHappenedAtOtherEmpty = !this.$refs?.stepOne?.happenedAtOther
      const isHappenedAtInvalid = isHappenedAtEmpty && isHappenedAtOtherEmpty

      const isMisconductsEmpty = misconducts.length < 1

      const isDescriptionEmpty = !description || description === ''

      const isAreasEmpty = !areas.length
        ? !areas.length && !customArea
        : !areas.length

      const isAccusedCollaborator = this.isSourceCollaborator(accusedSource)
      const isAreaMandatory =
        this.isFeatureEnabled('areaMandatory') || isAccusedCollaborator
      const isCompanyBranchEmpty = !companyBranch || companyBranch === ''

      const isCompanyBranchRequired =
        this.isFeatureEnabled('companyBranch') && isCompanyBranchEmpty

      if (this.currentStep === formSteps.stepOne) {
        if (this.displayOriginalFlow) {
          return (
            isHappenedToEmpty ||
            isMisconductsEmpty ||
            isHappenedAtInvalid ||
            isCompanyBranchRequired
          )
        }
        return (
          isHappenedToEmpty || isCompanyBranchRequired || isDescriptionEmpty
        )
      }

      if (this.currentStep === formSteps.stepTwo) {
        return this.displayOriginalFlow
          ? isDescriptionEmpty
          : isMisconductsEmpty
      }

      if (this.currentStep === formSteps.stepThree && isAreaMandatory) {
        return isAreasEmpty
      }

      if (this.currentStep === formSteps.stepThree) {
        return isAccusedSourceEmpty
      }

      return false
    },

    source() {
      return this.$route.params.source
    },

    isCollaborator() {
      if (Auth.currentUser?.email) {
        return true
      }

      return this.isSourceCollaborator(this.source)
    },

    displayOriginalFlow() {
      return this.experimentVariant === 'original'
    },
  },

  methods: {
    ...mapActions(['getNextCode', 'resetSMSModalFlow', 'getExperimentVariant']),

    async save() {
      if (this.isSubmitted) return
      this.isSubmitted = true

      const misconducts = this.report.misconducts.map((item) => item.id)
      this.report.misconducts = misconducts

      this.clearStorage()

      this.$emit('report:submit', this.report)
    },

    previousStep() {
      if (this.currentStep === 4) {
        return (this.currentStep = 3)
      }

      return (this.currentStep = this.currentStep - 1)
    },

    nextStep() {
      return (this.currentStep = this.currentStep + 1)
    },

    tracker: (field) => trackEvent('report_filled', { field: field }),

    back() {
      this.$router.go(-1)
    },

    handleStepLogin() {
      this.currentStep = this.isAnonymousUser ? 5 : 6
    },

    clearStorage() {
      const code = localStorage.getItem(CODE)
      localStorage.removeItem(code)
      localStorage.removeItem(CODE)
    },

    showReportWithoutCorpEmailPage() {
      this.report.metadata.isNoCorp = true
      this.report.anonymous = true
      this.currentStep = this.finalStep
    },

    handlePreviewAfterLogin() {
      this.report.anonymous = false
      this.currentStep = this.formSteps.stepPreview
    },

    beforeCloseWindow(event) {
      event.returnValue = true
      return event
    },
  },

  beforeDestroy() {
    window.onbeforeunload = null
  },

  watch: {
    currentStep: (_, step) => trackEvent('report_step', { step: step }),

    error() {
      this.showError = !!this.error
      if (this.error && this.currentStep === 0) {
        this.back()
      }
    },

    draft: {
      handler(newValue) {
        const draftIsEmpty = Object.keys(newValue).length === 0

        if (draftIsEmpty) {
          return
        }

        this.report = { ...newValue }
      },
      deep: true,
    },
  },
}
